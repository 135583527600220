import { default as indexKrz8aDLwDoMeta } from "/src/pages/[...id]/enrollment/index.vue?macro=true";
import { default as indexVDuRerpVFEMeta } from "/src/pages/[id]/enrollment/index.vue?macro=true";
import { default as indexA1akajItYWMeta } from "/src/pages/[id]/enrollment/raffleTerms/index.vue?macro=true";
import { default as indexYqC4zTV33KMeta } from "/src/pages/[id]/enrollment/raffleTermsEn/index.vue?macro=true";
import { default as indexVigVUTIEbaMeta } from "/src/pages/[id]/index.vue?macro=true";
import { default as indexbXVu0v5OpmMeta } from "/src/pages/[id]/securityToken/index.vue?macro=true";
import { default as index3d232f3Hx1Meta } from "/src/pages/enrollment/confirmation/index.vue?macro=true";
import { default as indexu7gclxVNzdMeta } from "/src/pages/enrollment/success/index.vue?macro=true";
import { default as indexHW787ta9qGMeta } from "/src/pages/enrollment/unlink/index.vue?macro=true";
import { default as indexRupEYGeNhcMeta } from "/src/pages/enrollment/unlink/success/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as _91id_938ssaCihBpFMeta } from "/src/pages/qrcode/[id].vue?macro=true";
export default [
  {
    name: "id-enrollment___en",
    path: "/en/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___de___default",
    path: "/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___de",
    path: "/de/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___fr",
    path: "/fr/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___pt",
    path: "/pt/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___es",
    path: "/es/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___it",
    path: "/it/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___nl",
    path: "/nl/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___en",
    path: "/en/:id()/enrollment",
    meta: indexVDuRerpVFEMeta || {},
    component: () => import("/src/pages/[id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___de___default",
    path: "/:id()/enrollment",
    meta: indexVDuRerpVFEMeta || {},
    component: () => import("/src/pages/[id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___de",
    path: "/de/:id()/enrollment",
    meta: indexVDuRerpVFEMeta || {},
    component: () => import("/src/pages/[id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___fr",
    path: "/fr/:id()/enrollment",
    meta: indexVDuRerpVFEMeta || {},
    component: () => import("/src/pages/[id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___pt",
    path: "/pt/:id()/enrollment",
    meta: indexVDuRerpVFEMeta || {},
    component: () => import("/src/pages/[id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___es",
    path: "/es/:id()/enrollment",
    meta: indexVDuRerpVFEMeta || {},
    component: () => import("/src/pages/[id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___it",
    path: "/it/:id()/enrollment",
    meta: indexVDuRerpVFEMeta || {},
    component: () => import("/src/pages/[id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___nl",
    path: "/nl/:id()/enrollment",
    meta: indexVDuRerpVFEMeta || {},
    component: () => import("/src/pages/[id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___en",
    path: "/en/:id()/enrollment/raffleTerms",
    component: () => import("/src/pages/[id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___de___default",
    path: "/:id()/enrollment/raffleTerms",
    component: () => import("/src/pages/[id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___de",
    path: "/de/:id()/enrollment/raffleTerms",
    component: () => import("/src/pages/[id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___fr",
    path: "/fr/:id()/enrollment/raffleTerms",
    component: () => import("/src/pages/[id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___pt",
    path: "/pt/:id()/enrollment/raffleTerms",
    component: () => import("/src/pages/[id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___es",
    path: "/es/:id()/enrollment/raffleTerms",
    component: () => import("/src/pages/[id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___it",
    path: "/it/:id()/enrollment/raffleTerms",
    component: () => import("/src/pages/[id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___nl",
    path: "/nl/:id()/enrollment/raffleTerms",
    component: () => import("/src/pages/[id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___en",
    path: "/en/:id()/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___de___default",
    path: "/:id()/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___de",
    path: "/de/:id()/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___fr",
    path: "/fr/:id()/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___pt",
    path: "/pt/:id()/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___es",
    path: "/es/:id()/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___it",
    path: "/it/:id()/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___nl",
    path: "/nl/:id()/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id___en",
    path: "/en/:id()",
    meta: indexVigVUTIEbaMeta || {},
    component: () => import("/src/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id___de___default",
    path: "/:id()",
    meta: indexVigVUTIEbaMeta || {},
    component: () => import("/src/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id___de",
    path: "/de/:id()",
    meta: indexVigVUTIEbaMeta || {},
    component: () => import("/src/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id___fr",
    path: "/fr/:id()",
    meta: indexVigVUTIEbaMeta || {},
    component: () => import("/src/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id___pt",
    path: "/pt/:id()",
    meta: indexVigVUTIEbaMeta || {},
    component: () => import("/src/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id___es",
    path: "/es/:id()",
    meta: indexVigVUTIEbaMeta || {},
    component: () => import("/src/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id___it",
    path: "/it/:id()",
    meta: indexVigVUTIEbaMeta || {},
    component: () => import("/src/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id___nl",
    path: "/nl/:id()",
    meta: indexVigVUTIEbaMeta || {},
    component: () => import("/src/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___en",
    path: "/en/:id()/securityToken",
    meta: indexbXVu0v5OpmMeta || {},
    component: () => import("/src/pages/[id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___de___default",
    path: "/:id()/securityToken",
    meta: indexbXVu0v5OpmMeta || {},
    component: () => import("/src/pages/[id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___de",
    path: "/de/:id()/securityToken",
    meta: indexbXVu0v5OpmMeta || {},
    component: () => import("/src/pages/[id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___fr",
    path: "/fr/:id()/securityToken",
    meta: indexbXVu0v5OpmMeta || {},
    component: () => import("/src/pages/[id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___pt",
    path: "/pt/:id()/securityToken",
    meta: indexbXVu0v5OpmMeta || {},
    component: () => import("/src/pages/[id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___es",
    path: "/es/:id()/securityToken",
    meta: indexbXVu0v5OpmMeta || {},
    component: () => import("/src/pages/[id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___it",
    path: "/it/:id()/securityToken",
    meta: indexbXVu0v5OpmMeta || {},
    component: () => import("/src/pages/[id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___nl",
    path: "/nl/:id()/securityToken",
    meta: indexbXVu0v5OpmMeta || {},
    component: () => import("/src/pages/[id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___en",
    path: "/en/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___de___default",
    path: "/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___de",
    path: "/de/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___fr",
    path: "/fr/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___pt",
    path: "/pt/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___es",
    path: "/es/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___it",
    path: "/it/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___nl",
    path: "/nl/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___en",
    path: "/en/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___de___default",
    path: "/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___de",
    path: "/de/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___fr",
    path: "/fr/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___pt",
    path: "/pt/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___es",
    path: "/es/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___it",
    path: "/it/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___nl",
    path: "/nl/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___en",
    path: "/en/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___de___default",
    path: "/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___de",
    path: "/de/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___fr",
    path: "/fr/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___pt",
    path: "/pt/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___es",
    path: "/es/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___it",
    path: "/it/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___nl",
    path: "/nl/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___en",
    path: "/en/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___de___default",
    path: "/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___de",
    path: "/de/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___fr",
    path: "/fr/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___pt",
    path: "/pt/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___es",
    path: "/es/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___it",
    path: "/it/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___nl",
    path: "/nl/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de___default",
    path: "/",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___en",
    path: "/en/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___de___default",
    path: "/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___de",
    path: "/de/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___fr",
    path: "/fr/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___pt",
    path: "/pt/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___es",
    path: "/es/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___it",
    path: "/it/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___nl",
    path: "/nl/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  }
]