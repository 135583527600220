<script setup lang="ts">
import type { NuxtError } from "nuxt/app";
import { useRouter } from "vue-router";

const props = defineProps({
  error: Object as () => NuxtError,
});

const router = useRouter();
</script>

<template>
  <v-container class="fill-height d-flex flex-column align-center justify-center text-center">
    <v-card
      class="pa-8"
      elevation="3"
      max-width="95%"
    >
      <v-img
        class="mx-auto my-5"
        src="/anybill_logo.svg"
        alt="Logo"
        width="180px"
      />
      <v-card-title class="text-h2 font-weight-bold my-5">
        {{ error?.statusCode ?? "404" }}
      </v-card-title>
      <div class="text-h6">
        {{ (error?.statusCode === 404 || error?.statusCode === null) ? $t('error.404') : $t('error.default') }}
      </div>
    </v-card>
  </v-container>
</template>
